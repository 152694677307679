@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
// @import '~@nebular/auth/styles/all';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  // @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


html .nb-theme-default nb-stepper .header .step .label-index{
  display: none;
}


html nb-stepper .header .step .label:hover {
  color: #3366ff;
}

html nb-stepper .header .step.selected .label {
  color: #3366ff;
}

html  nb-stepper .step-content {
  width: 100%;
}

button {
  outline: none !important;
}

td.mat-cell {
  padding-right: 15px !important;
}
th.mat-header-cell {
  padding-right: 15px !important;
}


.mat-dialog-container {
  border-radius: 20px !important;
}